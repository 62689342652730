import React, { useContext, useEffect, useState } from "react";
import Button from '../../Button/Button'
import style from './Contato.module.css'
import styleС from '../Contato/Contato.module.css'
import Title from "../../Title";
import { QtdContext } from "../../../context/qtdContext";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import RadioButton from './Radio/RadioButton'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import logos from './logo3h.png'
import ModalDatePicker from "../../Modal/ModalDatePicker.js";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { addDays, isWeekend } from 'date-fns';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru';
registerLocale('ru', ru)
const Order = () => {
  const delay = (ms) => {
    return new Promise((r) => setTimeout(() => r(), ms));
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [paytaped, setpaytaped] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [error1, setError1] = useState({
    email: false,
    ref: false
  });
  const [errorPromo, setErrorPromo] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [bonusALL, setBonusALL] = useState(0);
  const [timebonus, setTimeBonus] = useState(0);
  const [asked, setAsked] = useState(false);
  const [bonusTaked, setBonusTaked] = useState(false);

  const [promo, setPromo] = useState(0);
  const [promoTaked, setPromoTaked] = useState(false);
  const [askedpromo, setAskedpromo] = useState(false);

  // const handleDateChange = date => {
  //   console.log(date)
  //   setSelectedDate(date);
  //   setShowDatePicker(false); // Закрываем дейтпикер после выбора даты

  // };




  const [error, setError] = useState({
    name: false,
    phone: false,
    email: false
  });
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let telformat = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/

  const { errorOrder, opt, showDatePicker, setShowDatePicker, newOrder, newOrderID, setNewOrderID, setNewOrder, produtosSelecionados, setProdutosSelecionados } = useContext(QtdContext);
  // console.log(produtosSelecionados)

  function openModalDP(e) {
    e.preventDefault()
    setShowDatePicker(true)
  }
  // const [value, setValue] = useState('Доставка');

  // function chengeValue(target) {
  //   setValue(target);
  //   // console.log(value)
  // }
  // const [valueNal, setValueNal] = useState('Наличные/Карта при получении');

  // function chengeValueNal(target) {
  //   setValueNal(target);
  //   // console.log(value)
  // }

  const [ref, setRef] = useState(null);



  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (produtosSelecionados.length == 0) {
      // console.log('eerf')

      // return (<>
      //   <Title text="Сначала добавьте товар в корзину!" />
      // </>)

      navigate('/');
      return
    }
  }, [])

  let summ = 0
  let cartItem = [
    // {name:'Скидка',  price:"-10", quantity:'1', sum:'-10', tax:'none'}
  ]
  let totalsale = 0
  if (!bonusTaked && !promoTaked) {
    for (let i = 0; i < produtosSelecionados.length; i++) {
      cartItem[i] = {
        shcode: produtosSelecionados[i].shcode,
        name: produtosSelecionados[i].name,
        mnt: produtosSelecionados[i].mnt,
        price: produtosSelecionados[i].price,
        oldprice: produtosSelecionados[i].price,
        sale: 0,
        quantity: produtosSelecionados[i].quantidade,
        sum: Number(produtosSelecionados[i].quantidade) * Number(produtosSelecionados[i].price),
        tax: 'none',

      }
      summ = summ + cartItem[i].sum
    }
  }
  if (bonusTaked) {
    let priceSale = 0
    for (let i = 0; i < produtosSelecionados.length; i++) {
      priceSale = Number(produtosSelecionados[i].quantidade) * Number(produtosSelecionados[i].price) + priceSale
    }
    for (let i = 0; i < produtosSelecionados.length; i++) {
      const sale = ((Number(produtosSelecionados[i].price) * ((Number(bonus / priceSale * 100)) / 100)).toFixed(2))

      cartItem[i] = {
        shcode: produtosSelecionados[i].shcode,

        name: produtosSelecionados[i].name,
        sale: sale,
        mnt: produtosSelecionados[i].mnt,
        price: produtosSelecionados[i].price - sale,
        oldprice: produtosSelecionados[i].price,
        quantity: produtosSelecionados[i].quantidade,
        sum: Number(produtosSelecionados[i].quantidade) * (Number(produtosSelecionados[i].price) - Number(sale)),
        tax: 'none'
      }
      summ = summ + cartItem[i].sum
    }

    // console.log('bonus taked')
  }
  if (promoTaked) {
    for (let i = 0; i < produtosSelecionados.length; i++) {
      const sale = ((Number(produtosSelecionados[i].price) * ((Number(promo)) / 100)).toFixed(2))

      cartItem[i] = {
        shcode: produtosSelecionados[i].shcode,

        name: produtosSelecionados[i].name,
        sale: sale,
        mnt: produtosSelecionados[i].mnt,
        price: produtosSelecionados[i].price - sale,
        oldprice: produtosSelecionados[i].price,
        quantity: produtosSelecionados[i].quantidade,
        sum: Number(produtosSelecionados[i].quantidade) * (Number(produtosSelecionados[i].price) - Number(sale)),
        tax: 'none'
      }
      summ = summ + cartItem[i].sum
    }
  }


  // cartItem.map(({ sum}, index) => (
  //   summ = summ + sum
  // ))
  // summ.toFixed(2)
  // console.log(summ, produtosSelecionados, cartItem)

  const SendOrder = async (e) => {
    try {
      // console.log(newOrder)
      e.preventDefault();



      let t = {
        name: false,
        phone: false,
        email: false,
        DiliveryType: false,
        DiliveryCity: false,
        DiliveryAdress: false,
        DiliveryFlat: false,
        DiliveryHouse: false,
      }
      if (!mailformat.test(newOrder.email)) {
        t.email = 'Адрес Электронной Почты неверный!'
        // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
      }
      if (!telformat.test(newOrder.phone)) {
        t.phone = 'Номер телефона должен быть формата +79872349999!'
        // setError({ ...error, phone: 'Номер телефона обязателен для заполнения!' });
      }
      if (!newOrder.email) {
        t.email = 'Адрес Электронной Почты обязателен для заполнения!'
        // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
      }
      if (!newOrder.phone) {
        t.phone = 'Номер телефона обязателен для заполнения!'
        // setError({ ...error, phone: 'Номер телефона обязателен для заполнения!' });
      }
      if (!newOrder.name) {
        t.name = 'Имя обязателен для заполнения!'
        // setError({ ...error, name: 'Имя обязателено для заполнения!' });
      }
      if (!newOrder.DiliveryType) {
        t.DiliveryType = 'Необходимо выбрать способ доставки!'
        // setError({ ...error, name: 'Имя обязателено для заполнения!' });
      }

      if (newOrder.DiliveryType != 'Самовывоз') {
        if (summ < 1500 && !newOrder.DiliveryCity.toLowerCase().includes("калининград")) {
          t.DiliveryCity = 'Доставка вне Калининграда осуществляется от 1500р!'
        }
        // if (newOrder.DiliveryCity.toLowerCase() == "калининград") {

        // }
        if (!newOrder.DiliveryCity) {
          t.DiliveryCity = 'Необходимо выбрать город доставки!'
          // setError({ ...error, name: 'Имя обязателено для заполнения!' });
        }
        if (!newOrder.DiliveryAdress) {
          t.DiliveryAdress = 'Необходимо указать доставки!'
          // setError({ ...error, name: 'Имя обязателено для заполнения!' });
        }
        if (!newOrder.DiliveryFlat) {
          t.DiliveryFlat = 'Необходимо указать номер квартиры или офиса!'
          // setError({ ...error, name: 'Имя обязателено для заполнения!' });
        }
        if (!newOrder.DiliveryHouse) {
          t.DiliveryHouse = 'Необходимо указать номер дома или подьезда!'
          // DiliveryHouse({ ...error, name: 'Имя обязателено для заполнения!' });
        }
      }

      console.log(t, newOrder)
      setError(t)

      if (!t.name
        && !t.email
        && !t.phone
        && !t.DiliveryHouse
        && !t.DiliveryFlat
        && !t.DiliveryAdress
        && !t.DiliveryCity
        && newOrder.name
        && newOrder.phone
        && newOrder.email
        && newOrder.DiliveryType
        && newOrder.DiliveryType == 'Самовывоз') {
        setLoading(true)

        const sendedOrder = await axios.post(`${BACKURL}/api/neworderreact`, {
          user: { ...newOrder, paytype: 'Наличные/Карта при получении' },
          order: cartItem,
          promo: promoTaked ? promo : 0,
          bonus: bonusTaked ? bonus : 0
        })
        // console.log('new order', sendedOrder.data)
        setProdutosSelecionados([])
        localStorage.setItem("produtosSelecionados", JSON.stringify([]));
        // console.log(sendedOrder.data)
        setNewOrderID(sendedOrder.data)
        setLoading(false)


        setTimeout(() => {
          setNewOrderID('')
          navigate('/cuns')
          setNewOrderID('')
        }, 18000)
      }

      if (!t.name
        && !t.email
        && !t.phone
        && !t.DiliveryHouse
        && !t.DiliveryFlat
        && !t.DiliveryAdress
        && !t.DiliveryCity
        // && newOrder.name
        // && newOrder.phone
        // && newOrder.email
        // && newOrder.DiliveryCity
        // && newOrder.DiliveryType
        && newOrder.DiliveryType == 'Доставка до двери') {
        console.log('tut')
        let formattedDate = 'Вторая/Последняя пятница месяца'
        // let formattedDate1 = ''

        if (selectedDate && selectedTime) {
          const day = String(selectedDate.getDate()).padStart(2, '0'); // Получаем день месяца и добавляем ведущий ноль, если нужно
          const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Получаем месяц (начиная с 0) и добавляем ведущий ноль, если нужно
          const year = selectedDate.getFullYear(); // Получаем год
          formattedDate = `${day}/${month}/${year}`;

        }



        console.log(formattedDate)

        setpaytaped(true)
        setLoading(true)
        // console.log('Sended order', { user: { ...newOrder, paytype: 'Оплата картой или СБП' }, order: cartItem, selectedTime: selectedTime, selectedDate: formattedDate, promo: promoTaked ? promo : 0, bonus: bonusTaked ? bonus : 0 })

        const sendedOrder = await axios.post(`${BACKURL}/api/neworderreact`, {
          user: { ...newOrder, paytype: 'Оплата картой или СБП' },
          order: cartItem,
          selectedTime: selectedTime,
          selectedDate: formattedDate,
          promo: promoTaked ? promo : 0,
          bonus: bonusTaked ? bonus : 0
        })



        let form = document.createElement('form');
        form.action = 'https://kotopes39.server.paykeeper.ru/create/';
        form.method = 'POST';
        form.hidden = true
        let s = 0
        s = `<input name='sum' value="${summ}" />`;
        form.innerHTML = `${s}` +
          `<input name='orderid' value='${sendedOrder.data}'/>` +
          `<input name='clientid' value='${newOrder.name}'/>` +
          `<input name='client_email' value='${newOrder.email}'/>` +
          `<input name='client_phone' value='${newOrder.phone}'/` +
          `<input name='service_name'  value='Оплата в магазине натуральных кормов КотоPеs' /> ` +
          `<input name='user_result_callback'  value='https://kotopes39.ru/success/:${sendedOrder.data}' />` +
          `<input name='cart'  value='${JSON.stringify(cartItem)}' />` +
          document.body.append(form);

        form.submit();

        setProdutosSelecionados([])
        localStorage.setItem("produtosSelecionados", JSON.stringify([]));
        setpaytaped(false)
        setLoading(false)


      }
      // setLoading(false)

      // console.log('Error')

      // console.log(newOrder, produtosSelecionados, error)

    } catch (e) {
      setLoading(false)
      // console.log(e)
    }

  }


  const askPromo = async (e) => {
    // e.preventDefault();

    try {
      let t1 = false

      if (!(newOrder.promocodeName)) {
        t1 = 'Введите промокод!'
        // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
      }


      // console.log(t1)
      setErrorPromo(t1)

      if (newOrder.promocodeName && !t1.promocodeName) {
        // console.log('asked')
        const userBonus = await axios.post(`${BACKURL}/api/promocodes/check`, { code: newOrder.promocodeName })
        // console.log('bonuses', userBonus.data)
        // console.log('new order', sendedOrder.data)
        if (userBonus) {
          setPromo(userBonus.data)
          // setAskedpromo(true)
          // let r = newOrder
          // r.promocode = promo
          // r.bonus = 0
          // setNewOrder(r)
          // setNewOrder({ ...newOrder, bonus: 0 })
          // console.log(newOrder)
          setAskedpromo(true)
          // setAsked(false)
          setBonusTaked(false)
          setPromoTaked(true)
          setBonus(0)
          // setBonus(0)
        }






      }
    } catch (e) {
      // console.log(e)
      setErrorPromo(`${e.response?.data?.message}!`)

    }
  }



  const askBonus = async (e) => {
    e.preventDefault();

    let t1 = {

      email: false,

    }
    if (!mailformat.test(newOrder.email)) {
      t1.email = 'Адрес Электронной Почты неверный!'
      // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
    }

    if (!newOrder.email) {
      t1.email = 'Введите Адрес Электронной Почты выше!'
      // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
    }

    // console.log(t1)
    setError1(t1)
    if (newOrder.email && !t1.email) {
      // console.log('asked')
      const userBonus = await axios.post(`${BACKURL}/api/userbonus`, { mail: newOrder.email })
      // console.log('bonuses', userBonus.data)
      // console.log('new order', sendedOrder.data)
      setAsked(true)
      let bonus = 0
      if (Number(userBonus.data) > summ / 3) {
        bonus = (summ / 3).toFixed(0)
      } else {
        bonus = userBonus.data
      }
      setTimeBonus(bonus)
      setBonusALL(Number(userBonus.data))
      // setAskedpromo(false)
      // setPromoTaked(false)
      // setPromo(0)



    }
  }

  const askRef = async (e) => {
    e.preventDefault();
    // console.log(ref)
    let t1 = {
      email: false,
      ref: false,

    }

    if (!mailformat.test(newOrder.email)) {
      t1.email = 'Адрес Электронной Почты неверный!'
      // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
    }


    if (!newOrder.email) {
      t1.email = 'Введите Адрес Электронной Почты выше!'
      // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
    }
    if (!ref) {
      t1.ref = 'Введите Адрес Электронной Рефера выше!'
      // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
    }
    if (newOrder.email == ref) {
      t1.ref = 'Вы не можете указать свой адрес электронной почты!'
      // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
    }
    if (!mailformat.test(ref)) {
      t1.ref = 'Адрес Электронной Почты неверный!'
      // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
    }
    setError1(t1)
    if (!t1.email && !t1.ref && newOrder.email && ref) {
      try {
        setLoading(true)
        const userRef = await axios.post(`${BACKURL}/api/utbteam/users/checkref`, { mail: newOrder.email, ref: ref })
        // console.log(userRef)

        setRef(null)


        const reffer = userRef.data
        // console.log(reffer)

        if (!reffer) {
          t1.ref = 'Рефера нет в системе!'
        }

        setNewOrder({ ...newOrder, ref: reffer })
        // console.log(t1)
        setError1([])
        setLoading(false)
      } catch (e) {
        setLoading(false)
        setError1({ ref: e.response?.data?.message })
        // console.log(e.response?.data?.message)
      }


    }


  }

  const takeBonus = async (e) => {
    e.preventDefault();
    // setNewOrder({ ...newOrder, bonus: bonus })
    // setNewOrder({ ...newOrder, promocode: 0 })
    // console.log(newOrder)
    setBonus(timebonus)

    setAskedpromo(false)
    setAsked(false)
    setBonusTaked(true)
    setPromoTaked(false)
    setPromo(0)

  }





  const dis = (summ < 1500) ? true : false

  let colorDelivery = ''
  if (newOrder.DiliveryType == 'Доставка до двери') {
    colorDelivery = '#ffe0a6'
  }
  else { colorDelivery = '#efefef' }

  if (dis) { colorDelivery = '#FF9A9A' }

  return (
    <>

      <div className={style.border}>
        {!newOrderID && (
          <>


            {/* <p className={style.p}>На данный момент бесплатная доставка доступна при заказе от 500р! Доставка производится на следующий день после создания заказа! Суббота и Воскресенье - Выходные! Заказ на сумму ниже 500р будет доставлен Яндекс Доставкой!</p> */}

            <br />



            <br />
            <div>
              <h5 style={{ textAlign: 'center' }}>Заказ и скидки:</h5>

              <div className={style.container_table1}>

                <div className={style.promodiv}>
                  <label for="email">Ваш Адрес Электронной почты:</label>
                  <input value={newOrder.email} placeholder="Email" className={style.p} type="email" id="email" name="email" onChange={(event) => {
                    setNewOrder({ ...newOrder, email: event.target.value.toLowerCase() });
                    setBonus(0)
                    setBonusTaked(false)
                  }}
                  />
                  {error.email && (
                    <p style={{ color: "red" }}>{error.email}</p>
                  )}
                  {error1.email && (
                    <p style={{ color: "red" }}>{error1.email}</p>
                  )}
                </div>
                <p className={style.p}><b>Рефер</b> - это ваш друг, который позвал вас к нам на сайт. Если вы введете email друга - то вы будете получать повышенный кэшбэк бонусными баллами не 3%, а 5% навсегда. В то время как ваш друг будет получать 2%  бонусами от каждого вашего заказа.</p>
                <p style={{ fontSize: '14px' }} className={style.p}><b>*</b>Ввести email друга можно только один раз. В будущих заказах этого можно не делать</p>
                {/* <p style={{fontSize:'14px'}} className={style.p}><b>*</b></p> */}

                {!newOrder.ref && (
                  <div className={style.promodiv}>
                    <label for="email">Адрес Электронной Почты Рефера:</label>
                    <input value={ref} placeholder="Реферер" className={style.p} type="email" id="email" name="email" onChange={(event) => {
                      setRef(event.target.value.toLowerCase());
                      // console.log(ref)
                      // setBonus(0)
                      // setBonusTaked(false)
                    }}
                    />

                    {error1.ref && (
                      <p style={{ color: "red" }}>{error1.ref}</p>
                    )}
                    <Button text='Проверить рефера!'
                      onClick={askRef}
                    />
                  </div>
                )}
                {newOrder.ref && (
                  <div className={style.promodiv}>
                    <label for="email">Рефер успешно добавлен!</label>
                    <Button text={newOrder.ref.email}
                      background='#20b6e8'
                    // onClick={askRef}
                    />
                  </div>
                )}

                <p className={style.p}>При самостоятельном заборе заказа из нашего магазина по адресу Советский Проспект 204 вы получите <b>10% скидки</b>!</p>
                <h4 className={style.p}>Доставка осуществляется только по будням!</h4>
                <div className={style.container_pages3}>
                  <div className={style.container_pages4}>
                    <div className={style.form_radio_btn}>
                      <label style={{ background: colorDelivery }} for="radio-1" >Доставка до двери</label>
                      <input disabled={dis} id="radio-1" type="radio" name="Доставка до двери" value="Доставка до двери"
                        checked={newOrder.DiliveryType == 'Доставка до двери' ? true : false}
                        style={{ background: newOrder.DiliveryType == 'Доставка до двери' ? '#ffe0a6' : '#efefef' }}
                        onChange={() => {
                          // chengeValueNal('Наличные')newOrder.paytype
                          setNewOrder({ ...newOrder, DiliveryType: 'Доставка до двери' })
                          setPromo(0)

                          // setAskedpromo(false)
                          // setAsked(false)
                          setBonusTaked(false)
                          setPromoTaked(false)
                        }
                        } />
                    </div>


                    <div className={style.form_radio_btn}>
                      <label style={{ background: newOrder.DiliveryType == 'Самовывоз' ? '#ffe0a6' : '#efefef' }}
                        for="radio-2" >Самовывоз</label>
                      <input id="radio-2" type="radio" name="Самовывоз" value="Самовывоз"
                        checked={newOrder.DiliveryType == 'Самовывоз' ? true : false}
                        style={{ background: newOrder.DiliveryType == 'Самовывоз' ? '#ffe0a6' : '#efefef' }}

                        onChange={() => {
                          setNewOrder({ ...newOrder, DiliveryType: 'Самовывоз' })
                          setPromo(10)
                          // setAskedpromo(true)
                          // setAsked(false)
                          setBonusTaked(false)
                          setPromoTaked(true)
                          setBonus(0)
                          // setAskedpromo(false)
                          // // setAsked(false)
                          // setBonusTaked(false)
                          // setPromoTaked(false)

                          // chengeValueNal('Оплата картой или СБП')
                        }} />
                    </div>
                  </div>
                  <div>
                    {dis && (
                      <h4 className={style.p}>Доставка осуществляется только от 1500р! Вне Калининграда доставка осуществляется каждую ВТОРУЮ И ПОСЛЕДНЮЮ ПЯТНИЦУ МЕСЯЦА! (9/08/24, 30/08/24, 13/09/24 и 27/09/24)</h4>
                    )}
                  </div>
                </div>
                {/* {newOrder.DiliveryType && ( */}
                <br />
                <div style={{ 'overflow-x': "auto", "width": "80%", 'flexDirection': 'column', 'display': 'flex' }}>
                  <ol>

                    {!bonusTaked && !promoTaked && (<>
                      {cartItem.map(({ name, mnt, quantity, price, sum }, index) => {

                        return <>
                          <li><b>{index + 1}.</b> {name}<br></br> {quantity}{mnt} X {price}руб = <b>{sum.toFixed(2)} руб</b></li>
                          <br /></>
                      }
                      )}</>)
                    }

                    {bonusTaked && (<>

                      {cartItem.map(({ name, quantity, price, sum, mnt, oldprice, sale }, index) => {

                        return <>

                          <li><b>{index + 1}.</b> {name}<br></br> {quantity}{mnt} X {oldprice}руб - {sale} руб = <b>{sum.toFixed(2)} руб</b><br /></li>
                          <br /></>


                      })}
                    </>
                    )}
                    {promoTaked && (<>

                      {cartItem.map(({ name, quantity, oldprice, price, mnt, sale, sum }, index) => {

                        // console.log(priceNum, total, total.toFixed(2))
                        return <>
                          <li><b>{index + 1}.</b> {name}<br></br> {quantity}{mnt} X {oldprice}руб - {sale} руб = <b>{sum.toFixed(2)} руб</b><br /></li>
                          <br /></>

                      })}
                    </>
                    )}
                  </ol>
                  <b>Итого {summ.toFixed(2)}руб</b>

                </div>
                {/* )} */}

              </div>
              {/* {newOrder.DiliveryType && ( */}
              <div className={style.bonuses}>
                <p className={style.p}>Вы можете получить скидку введя промокод или списав балы с бонусного счета!</p>

                <div className={style.promodiv}>
                  {/* {newOrder.DiliveryType == 'Самовывоз' && ( */}
                  <>
                    <label for="promo">Использовать Промокод</label>
                    <div className={style.gth}>
                      <input className={style.p} placeholder="Промокод" type="text" id="promo" name="promo"
                        onChange={(event) => {
                          setNewOrder({ ...newOrder, promocodeName: event.target.value });
                        }}
                      />
                      <Button text='Применить!' onClick={askPromo} />
                    </div>
                    {errorPromo && (
                      <p style={{ color: "red" }}>{errorPromo}</p>
                    )}
                  </>
                  {/* )} */}
                  {askedpromo
                    // && newOrder.DiliveryType == 'Доставка' 
                    && (
                      <>
                        {promo > 0 && (<>
                          <p className={style.p}>Данный промокод дает <b>{promo}% скидки</b>!</p>
                          {/* <Button text='Активировать!' background='#20b6e8' onClick={takePromo} /> */}
                        </>

                        )}

                      </>
                    )}

                </div>
                <p className={style.p}>После каждого выполненого заказа на ваш личный счет начисляются бонусные баллы, их вы можите списывать на оплату до 30% суммы будующих покупок!</p>




                {/* ///// */}
                {/* {timebonus>0 && ( */}
                <div className={style.promodiv}>

                  <Button text='Проверить бонусный баланс!' onClick={askBonus} />
                  {error1.email && (
                    <p style={{ color: "red" }}>{error1.email}</p>
                  )}

                  {asked && (
                    <>
                      {timebonus > 0 && (<>
                        <p className={style.p}>У вас доступно <b>{timebonus} руб</b> для списания! Всего на вашем счету {bonusALL} руб</p>
                        <Button text='Списать бонусы!' background='#20b6e8' onClick={takeBonus} />
                      </>

                      )}
                      {timebonus == 0 && (
                        <p className={style.p}>На вашем счету нет бонусов!</p>

                      )}
                    </>
                  )}
                </div>
                {/* )} */}



              </div>
              {/* )} */}

              {/* <br></br> */}
            </div>
            <div>
              <h5 style={{ textAlign: 'center' }}>Получатель заказа:</h5>

              <div className={style.container_pages}>
                <p className={style.p}>Заполните данные для оформления заказа</p>

                <form className={style.container_pages}>
                  <label for="name">Ваше Имя:</label>
                  <input type="text" placeholder="Ваше Имя" value={newOrder.name} id="name" name="name" onChange={(event) => {
                    setNewOrder({ ...newOrder, name: event.target.value });
                  }} />
                  {error.name && (
                    <p style={{ color: "red" }}>{error.name}</p>
                  )}
                  <br></br>


                  <label for="tel">Ваш Номер Телефона:</label>
                  <input type="text" placeholder="Телефон" value={newOrder.phone} id="tel" name="tel" onChange={(event) => {
                    setNewOrder({ ...newOrder, phone: event.target.value });
                  }} />
                  {error.phone && (
                    <p style={{ color: "red" }}>{error.phone}</p>
                  )}

                </form>


              </div>


            </div>
            <div>

              <div className={style.container_pages}>


                {newOrder.DiliveryType == 'Доставка до двери' && (<>
                  <p className={style.p}>На данный момент бесплатная доставка доступна при заказе от 1500р!  <br />Суббота и Воскресенье - Выходные для доставки! <br />Вне Калининграда заказы доставляются от 1500р каждую ВТОРУЮ И ПОСЛЕДНЮЮ ПЯТНИЦУ МЕСЯЦА! (9/08/24, 30/08/24, 13/09/24 и 27/09/24)</p>
                  <form className={style.container_pages}>
                    <div className={style.adressInput}>
                      <label for="city">Город:</label>

                      <input value={newOrder.DiliveryCity} style={{ 'text-align': 'center' }} className={style.input} name="pets" id="city" placeholder="Город" type="text"
                        onChange={(event) => {
                          setNewOrder({ ...newOrder, DiliveryCity: event.target.value });
                        }}
                      />

                      {error.DiliveryCity && (
                        <p style={{ color: "red" }}>{error.DiliveryCity}</p>
                      )} </div>


                    <div className={style.adressInput}>
                      <label for="address">Адрес:</label>

                      <input value={newOrder.DiliveryAdress} style={{ 'text-align': 'center' }} name="address" id="address" autoComplete="street-address" placeholder="Улица" type="text"
                        onChange={(event) => {
                          setNewOrder({ ...newOrder, DiliveryAdress: event.target.value });
                        }} />
                      {error.DiliveryAdress && (
                        <p style={{ color: "red" }}>{error.DiliveryAdress}</p>
                      )}</div>

                    {/* </div>
                      <div className={style.adress2}> */}
                    <div className={style.adressInput}>
                      <label for="house">Дом/Подьезд:</label>

                      <input value={newOrder.DiliveryHouse} style={{ 'text-align': 'center' }} name="house" id="house" autoComplete="street-address" placeholder="Дом/Подьезд" type="text"
                        onChange={(event) => {
                          setNewOrder({ ...newOrder, DiliveryHouse: event.target.value });
                        }} />
                      {error.DiliveryHouse && (
                        <p style={{ color: "red" }}>{error.DiliveryHouse}</p>
                      )}</div>
                    <div className={style.adressInput}>
                      <label for="flat">Квартира/Офис:</label>

                      <input value={newOrder.DiliveryFlat} style={{ 'text-align': 'center' }} name="house" id="flat" autoComplete="street-address" placeholder="Квартира/Офис" type="text"
                        onChange={(event) => {
                          setNewOrder({ ...newOrder, DiliveryFlat: event.target.value });
                        }} />
                      {error.DiliveryFlat && (
                        <p style={{ color: "red" }}>{error.DiliveryFlat}</p>
                      )}</div>

                    {newOrder.DiliveryCity && (

                      <>
                        {newOrder.DiliveryCity.toLowerCase().includes('калининград') && (
                          <>
                            <div className={style.addressInput}>

                              {/* <h5>{selectedDate ? `Выбранная дата доставки: ${selectedDate.toLocaleDateString()}` : 'Выберите дату доставки'}</h5> */}
                              <Button text={selectedDate ? `Выбранная дата доставки: ${selectedDate.toLocaleDateString()}` : 'Выберите дату доставки'} onClick={openModalDP}></Button>
                              {error.selectedDate && (
                                <p style={{ color: "red" }}>{error.selectedDate}</p>
                              )}
                            </div>

                            <div className={style.adressInput}>
                              <select placeholder="Выберите время доставки" onChange={(event) => setSelectedTime(event.target.value)}>
                                <option value=''>Выберите время доставки </option>
                                <option value='с 12:00 до 16:00'>с 12:00 до 16:00</option>
                                <option value='с 16:00 до 20:00'>с 16:00 до 20:00</option>
                                {/* <option value='с 17:00 до 21:00'>с 17:00 до 21:00</option> */}
                              </select>
                              {error.selectedTime && (
                                <p style={{ color: "red" }}>{error.selectedTime}</p>
                              )}
                            </div>
                          </>
                        )}


                      </>
                    )}

                    {/* <input name="unit" placeholder="Unit number" type="text" /> */}
                    {/* <input name="state" placeholder="State" type="text" /> */}
                    {/* <input name="country" placeholder="Country" type="text" /> */}
                    {/* <input name="postcode" placeholder="Postcode" type="text" /> */}
                  </form>
                </>

                )}
                {newOrder.DiliveryType == 'Самовывоз' && (
                  <p className={style.p}>Самовывоз доступен по адресу: город Калининград, Советский проспект 204. Наш магазин работает каждый день! По будням с 11:00 до 20:00, по выходным с 12:00 до 19:00!</p>

                )}



              </div>

            </div>
            {newOrder.DiliveryType && (
              <div>
                {/* <h5 style={{ textAlign: 'center' }}>Выберите способ оплаты:</h5> */}



                <div className={style.container_pages}>

                  {newOrder.DiliveryType == 'Самовывоз' && <>
                    <Button text='Оформить Заказ' onClick={SendOrder} />
                  </>
                  }
                  {newOrder.DiliveryType == 'Доставка до двери' && <>
                    {!paytaped && (
                      <Button text='Перейти к оплате' onClick={SendOrder} />
                    )}

                    {paytaped && (
                      <Button text='Перенаправляем на платежную систему...' disabled={true} />
                    )}
                  </>
                  }
                </div>
              </div>
            )}




          </>
        )}
        {newOrderID && (
          <>
            <Title text={'Заказ Создан!'} />
            <p style={{ textAlign: "center" }}>{`Спасибо что выбрали нас! Номер вашего заказа №`}<b>{newOrderID}</b>{`! В скором времени с васм свяжется менеджер для уточнения заказа!`}</p>
          </>
        )}
      </div>
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
      {showDatePicker && (
        <ModalDatePicker
          // showDatePicker={showDatePicker}
          selectedDate={selectedDate}
          // setShowDatePicker={setShowDatePicker}
          setSelectedDate={setSelectedDate}


        ></ModalDatePicker>
      )}

      {/* // } */}
    </>
  )

}
export default Order